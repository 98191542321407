<template>
  <div class="diagnosis_box">
    <div class="htop">
      <div class="head">
        <span>{{ selectItem.DiagnosisYear }}年</span>
        <span>{{ selectItem.DiagnosisName }}</span>
        <span>{{ selectItem.SubjectTypeStr }}</span>
      </div>
    </div>
    <div class="sform">
      <div class="clear"></div>
      <div class="box">
        <div class="title">诊断分数：</div>
        <el-input v-model.number="score" placeholder="请输入诊断分数" maxlength="3"></el-input>
        <el-button @click="activateDiagnosis" type="primary">换算</el-button>
        <div class="clear"></div>
      </div>
      <div v-if="showResult" class="show">
        <div class="title">相当于近年高考</div>
        <span class="show-item">成绩：<span class="show-content">{{ result.Score || '-' }}</span></span>
        <span class="show-item">全省排名：<span class="show-content">{{ result.Rank || '-' }}</span></span>
        <div class="clear" style="height:40px;"></div>
      </div>


      <div v-if="showWishFill && TBConfig.length>0" class="to-wish">
        <div class="name">模拟填报</div>
        <div class="tips">使用当前换算后的成绩，查看对应的模拟志愿推荐</div>
        
        <div class="bottom">
          <div v-if="WishFillStatus" class="bottom-box">
            <div v-if="TBConfig.includes('WDF')" class="button first-button" @click="fillWish('1')">{{nameConfig.WDF}}填报</div>
            <div v-if="TBConfig.includes('WCF')" class="button" @click="fillWish('0')">{{nameConfig.WCF}}填报</div>
          </div>
          <div v-else class="button one-button" @click="fillWish()">填报</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import API from '@/api/config'
import { getStore, setStore } from "@/utils/utils";
import {areaData} from '@/utils/data';
// import { LineDiagnosisScore } from '@/api/common'

export default {
  name: "diagnosis-info",
  data(){
    return{
      score: '',
      result: {},
      showResult: false,
      selectItem: {},

      showWishFill: false,
      
      WishFillStatus: true,
      wishType: 0,
      TBConfig: [],
      provinceObj: {},
      
      wishYear: '',
      nameConfig: {},
      
      ProvinceId: '',
      ProvinceName: '',
      
      studentReport: {
        ProvinceId: '',
        ProvinceName: '',
        Score: 0,
        SubjectList: [],
        SubjectType: null,
        Year: '',
        wishCategory: 0,
      }
    }
  },
  mounted(){
    this.selectItem = JSON.parse(this.$route.query.info)
    this.ProvinceId = JSON.parse(this.$route.query.ProvinceId)
    this.ProvinceName = JSON.parse(this.$route.query.ProvinceName)

    this.$store.dispatch('tool/getSystemYear').then(year => {
      this.wishYear = year

      this.handleConfig()

      let t = getStore('ProductAlias')

      if (!t) return
      
      this.nameConfig = JSON.parse(t)
    })
  },
  methods: {
    fillWish(val) {
      if (val) {
        this.wishType = Number(val)
      }
      this.studentReport.wishCategory = this.wishType
      
      setStore('studentReport', this.studentReport)
      
      this.$router.push("/recommend-start")
    },

    handleConfig() {
      this.provinceObj = JSON.parse(getStore('OrgProvince')) || {}
      
      let temp = getStore('ProductCodes');

      if (!temp) return
      temp = JSON.parse(temp)
      
      this.TBConfig = temp.filter(item => {
        if (item === 'WDF' || item === 'WCF') {
          return item
        }
      })
      
      if (!this.provinceObj.EnableWC) {
        let i = this.TBConfig.indexOf('WCF')
        if (i!=-1) {
          this.TBConfig.splice(i,1)
        }
      }
      
      if (!this.provinceObj.EnableWD) {
        let i = this.TBConfig.indexOf('WDF')
        if (i!=-1) {
          this.TBConfig.splice(i,1)
        }
      }
      if (this.TBConfig.length === 1) {
        if (this.TBConfig.includes('WCF')) {
          this.wishType = 0
        }
        if (this.TBConfig.includes('WDF')) {
          this.wishType = 1
        }
        
        this.WishFillStatus = false
      }
      if (this.TBConfig.length > 1) {
        
        this.WishFillStatus = true
      }
      
    },
    
    // 计算分数
    async activateDiagnosis(){
      if(!this.score && this.score !== 0){
        this.$message({
          type: 'error',
          message: '请输入诊断成绩！',
          center: true,
          offset: 120,
          showClose: true
        })
        return
      }
      if(!Number.isInteger(this.score)){
        this.$message({
          type: 'error',
          message: '分数格式不正确！',
          center: true,
          offset: 120,
          showClose: true
        })
        return
      }
      let loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen:false
      })
      let temp = await API.Public.LineDiagnosisScore({
        DiagnosisId: this.$route.query.id,
        DiagnosisScore: this.score
      })
      this.result = temp.data
      this.showResult = true
      loading.close()
      if(!temp.data.Score){
        this.$message({
          type: 'info',
          message: '暂无数据',
          offset: 120,
          showClose: true
        })
      } else {
        this.handleWish()
      }
    },

    // 判断是否显示模拟填报
    handleWish() {
      // 是否完善了各种信息
      API.User.CheckUserStudentComplate().then(res => {
        if (res.data.IsNeedComplateBaseInfo || res.data.IsNeedComplateScore || res.data.IsNeedComplateSubjects) {
          this.showWishFill = false
        } else {
          
          this.$store.dispatch('user/getStudentInfo').then(userInfo => {
            if (userInfo.UserScore) {
              
              let t = areaData[userInfo.UserScore.ExamProvinceId]

              if (t.toLowerCase()===this.ProvinceId) {
                
                this.studentReport.ProvinceId = userInfo.UserScore.ExamProvinceId
                this.studentReport.ProvinceName = userInfo.UserScore.ExamProvinceName
                this.studentReport.SubjectList = userInfo.UserScore.SubjectList
                this.studentReport.SubjectType = userInfo.UserScore.SubjectType
                this.studentReport.Year = this.Year
                this.studentReport.Score = this.result.Score
                this.studentReport.Rank = this.result.Rank
                
                this.showWishFill = true
              } else {
                this.showWishFill = false
              }
            
            } else {
              this.showWishFill = false
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.diagnosis_box{
  max-width: 1420px;
  margin: auto;
  min-height: 600px;
  .htop{
    width: 100%;
    height: 70px;
    background-color: rgba(var(--themecolor),0.2);
    text-align: center;
    .head{
      color: #333;
      font-size: 18px;
      line-height: 70px;
      span{
        margin: 0px 10px;
      }
    }
  }
  .sform{
    border: 1px solid #e0e0e0;
    border-top: none;
    min-height: 150px;
    text-align: center;
    .box{
      width: 486px;
      margin: 50px auto;
      .title{
        width: 76px;
        color: #666;
        font-size: 14px;
        line-height: 50px;
        float: left;
        text-align: right;
      }
    }
    .show{
      border-top: 1px dashed #d4d4d4;
      .title{
        line-height: 40px;
        padding-top: 25px;
      }
      &-item{
        margin: 10px 20px 0;
        display: inline-block;
        .show-content{
          color: rgba(var(--themecolor),1);
          font-size: 30px;
        }
      }
    }

    .to-wish {
			padding: 40px 0;
			margin-top: 40px;
			border-top: 1px solid #eaeaea;
			.name {
        margin-bottom: 5px;
				font-size: 16px;
				font-weight: 700;
        color: #444;
			}
			.tips {
				font-size: 12px;
				color: #999;
			}
			
			.bottom {
				.bottom-box {
					display: flex;
					justify-content: center;
				}
				.button {
					width: 300px;
					padding: 10px;
					margin-top: 20px;
					border-radius: 5px;
					text-align: center;
          border: 1px solid rgba(var(--themecolor), 0.6);
          background-color: rgba(var(--themecolor), 0.1);
					
					color: #777;
          cursor: pointer;
				}
        .first-button {
          margin-right: 20px;
        }
				.one-button {
					width: 300px;
					margin: 20px auto;
				}
			}
		}
  }
}

</style>
<style lang="less">
.diagnosis_box .el-input{
  float: left;
  width: 300px;
}
.diagnosis_box .el-input__inner{
  height: 50px;
  font-size: 20px;
}
.diagnosis_box .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}



.diagnosis_box .el-button--primary{
  width: 100px;
  height: 50px;
  margin-left: 10px;
  float: left;
  font-size: 18px;
  letter-spacing: 2px;
}
.diagnosis_box .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.diagnosis_box .el-button--primary,.diagnosis_box .el-button--primary:focus{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}

</style>